import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HeadingModule from "../components/modules/heading-module"
import { useRoomsData } from "../hooks/use-rooms-data"
import GuestroomsCards from "../components/rooms/guestrooms-cards"
import SuitesCards from "../components/rooms/suites-cards"
class RoomsPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

    const HeadingContainer = () => {
      const { heading } = useRoomsData();
      return (
        <HeadingModule
          headingText={ heading.headingText }
          subHeadingText={ heading.subHeadingText }
          subHeadingMaxWidth={ heading.subHeadingMaxWidth}
          classes=""
        />
      )
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Rooms - Niwot Inn &amp; Spa" />
        <div className="rooms-page">
          <HeadingContainer />
          <section className="darker py-5">
            <div className="container">
              <div className="row">
                <div className="col-100">
                  <h2 style={{ marginBottom: 0, marginTop: 0 }}>Suites &amp; Apartments</h2>
                </div>
              </div>
              <SuitesCards />
              <div className="row">
                <div className="col-100">
                  <h2 style={{ marginBottom: 0, marginTop: '30px' }}>Standard Guestrooms</h2>
                </div>
              </div>
              <GuestroomsCards />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default RoomsPage

export const roomsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
