import { useStaticQuery, graphql } from "gatsby"
export const useRoomsData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesRoomsYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
        }
      }
    `
  )
  return data.pagesRoomsYaml
}