import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const SuitesCards = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark( filter: {frontmatter: { path: {eq: "/rooms"}, category: {eq: "suite"} }} ) {
                    edges {
                        node {
                            excerpt
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                description
                                category
                                mainImage {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const rooms = data.allMarkdownRemark.edges;

    return (
        <div className="row">
            {rooms.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug
              return (
                <article key={ node.fields.slug } className="col-100 col-small-1-2 col-medium-1-3">
                  <Link to={ node.fields.slug }>
                    <div className="card room-card card-link">
                      <div className="card-link-image-container">
                        <Img
                          fluid={ node.frontmatter.mainImage.childImageSharp.fluid }
                          objectFit="cover"
                          objectPosition="50% 50%"
                          alt={ node.fields.slug }
                        />
                      </div>
                      <h3>{title}</h3>
                      <div className="button pull-right">
                          View Room
                      </div>
                    </div>
                  </Link>
                </article>
              )
            })}
        </div>
    )
}

export default SuitesCards
